import React, { HTMLProps } from 'react';
import { SetStateAction, useState } from 'react';
import { GoPlus } from 'react-icons/go';
import { IoRemoveCircleOutline } from 'react-icons/io5';
import { MatchesType, SelectionType } from '../../routes/seasons/routes/matches/generate-match';
import { FormInput } from '../form-input/form-input';

type MatchesStateType = {
  ht?: { id: number; teamName: string };
  at?: { id: number; teamName: string };
}[];
export function MatchSection({
  handleSelection,
  selections,
  matches,
  setMatches,
}: {
  handleSelection: (selectProps: SelectionType<'match'>, index?: number) => void;
  selections?: SelectionType<'match' | 'team'>;
  matches?: MatchesType;
  setMatches: React.Dispatch<SetStateAction<MatchesType>>;
}) {
  function addMatch() {
    setMatches((m) => [...m, {}]);
  }
  function removeMatch(index: number) {
    setMatches((m) => m.filter((a, i) => i !== index));
  }

  console.log('super matches', matches);

  return (
    <>
      <div
        data-after={matches?.length}
        className={`uppercase flex  text-center relative justify-between m-auto`}
      >
        <h3 className='text-center'>Matches</h3>
      </div>
      <div className='flex flex-col font-bold gap-2 pr-2 h-full overflow-auto'>
        {matches?.length ? (
          matches
            .sort((a, b) => {
              return (
                (a.groupId ?? Number.MAX_SAFE_INTEGER) - (b.groupId ?? Number.MAX_SAFE_INTEGER)
              );
            })
            .map((m, i) => {
              const previousMatch = matches[i - 1];
              const isNewGroup = previousMatch?.groupId !== m.groupId;

              return (
                <>
                  {isNewGroup && (
                    <h2 className='mt-2 ml-2 w-fit border-b-2 border-black'>
                      {m.groupName || 'Ungrouped'}
                    </h2>
                  )}

                  <MatchRow
                    key={i}
                    index={i}
                    at={m?.at?.teamName ?? ''}
                    ht={m?.ht?.teamName ?? ''}
                    removeMatch={removeMatch}
                    onClick={(team: 'ht' | 'at') =>
                      handleSelection({ index: i, team, type: 'match' }, i)
                    }
                    isActive={
                      selections?.type === 'match' && selections?.index === i
                        ? (selections.team as string)
                        : false
                    }
                    atBenched={m?.at?.isBenched ?? 0}
                    htBenched={m?.ht?.isBenched ?? 0}
                  />
                </>
              );
            })
        ) : (
          <p className='p-2'>No matches to display</p>
        )}
        <p
          onClick={() => addMatch()}
          className='mx-auto text-main flex items-center cursor-pointer hover:scale-110 duration-150 select-none'
        >
          <GoPlus size={20} /> Add another match
        </p>
      </div>
      <div className='flex items-start justify-between p-2 w-fit font-normal'>
        <FormInput type='datetime-local' name='match_date' label='Start Date' />
      </div>
    </>
  );
}
interface TeamComponentType extends HTMLProps<HTMLDivElement> {
  isActive?: boolean;
  name?: string;
  benched?: number;
}
function TeamComponent({ onClick, isActive, name, benched }: TeamComponentType) {
  return (
    <span
      onClick={onClick}
      className={`select-none w-full bg-white p-3 
                        font-normal rounded-lg border-2 truncate 
                        flex items-center h-[44px] cursor-pointer hover:bg-slate-50 ${
                          isActive ? '!bg-green-100' : ''
                        } `}
    >
      {/* <p className='text-gray-500 truncate'>{name ?? ''}</p> */}
      {benched ? (
        <div className='flex gap-2 w-full'>
          <div className='max-w-[80%]'>
            <p className='text-gray-500 truncate'>{name ?? ''}</p>
          </div>
          <div>
            <span className='text-[#B50303] font-[600] font-[Inter] text-[12px]'>BENCHED</span>
          </div>
        </div>
      ) : (
        <p className='text-gray-500 truncate'>{name ?? ''}</p>
      )}
    </span>
  );
}
interface MatchRowType {
  index: number;
  ht?: string;
  at?: string;
  removeMatch: (i: number) => void;
  onClick: (team: 'ht' | 'at') => void;
  isActive?: string | boolean;
  htBenched?: number;
  atBenched?: number;
}
function MatchRow({
  index,
  removeMatch,
  onClick,
  isActive,
  ht,
  at,
  htBenched,
  atBenched,
}: MatchRowType) {
  return (
    <div className='flex flex-col sm:flex-row items-center justify-between gap-2 p-2 pr-0 border-b-1 '>
      <IoRemoveCircleOutline
        onClick={() => removeMatch(index)}
        className='text-main hidden sm:text-[40px] sm:flex cursor-pointer hover:scale-110 duration-150'
      />
      <p
        onClick={() => removeMatch(index)}
        className='p-1 px-2 text-sm font-normal rounded-md bg-main text-white uppercase sm:hidden cursor-pointer select-none hover:opacity-90'
      >
        Remove
      </p>
      <TeamComponent
        name={ht ?? ''}
        benched={htBenched}
        isActive={isActive === 'ht'}
        onClick={() => onClick('ht')}
      />
      VS
      <TeamComponent
        name={at ?? ''}
        benched={atBenched}
        isActive={isActive === 'at'}
        onClick={() => onClick('at')}
      />
    </div>
  );
}
