import React, { useState } from 'react';
import { BsFillArrowDownCircleFill } from 'react-icons/bs';
import { CurrentTeamMatch } from '../edit-team-members/CurrentTeamMatch';
import { SubstitudesMatch } from '../edit-team-members/SubstitudesMatch';
import { useSelector } from 'react-redux';
import { selectMatchData } from '../../../slices/matchSlice';
import { MatchStatus } from '../../../utils/constants';
export function HomeTeamMatchInfo({ canEdit }: { canEdit: boolean }) {
  const btnList = [
    {
      title: 'Edit',
      onClick: () => setEditMode(true),
      edit: false,
    },
    {
      title: 'Cancel',
      onClick: () => setEditMode(false),
      edit: true,
    },
  ];

  const matchData = useSelector(selectMatchData);

  const [editMode, setEditMode] = useState(false);
  const [showAvailable, setShowAvailable] = useState(false);
  return (
    <div className='flex gap-2 flex-col w-fit'>
      <h2 className='text-center'>Current Roster</h2>
      <CurrentTeamMatch editMode={editMode} />
      {matchData.status === MatchStatus.InProgress ||
      matchData.status === MatchStatus.Upcoming ||
      matchData.status === MatchStatus.UpcomingRescheduledFor ||
      matchData.status === MatchStatus.RescheduleRequested ||
      matchData.status === MatchStatus.Completed ? (
        <div className='flex relative p-2 text-xs bg-gray-200  rounded-lg justify-around items-center leading-tight'>
          <div
            className='flex justify-center items-center gap-2 cursor-pointer'
            onClick={() => {
              setShowAvailable(!showAvailable);
              setEditMode(false);
            }}
          >
            <BsFillArrowDownCircleFill color='#2b1462' size={20} />{' '}
            <h2 className='text-[13px]'>Available Players</h2>
          </div>
          <div>
            {showAvailable && (
              <div className='flex flex-col mt-4 p-0 gap-2 rounded-xl pb-4 w-[250px] absolute top-[100%] left-0'>
                <div className='flex justify-start'>
                  {canEdit &&
                    btnList
                      .filter((b) => b.edit === editMode)
                      .map((b, i) => (
                        <div
                          className='px-4 py-2 bg-gray-200 rounded cursor-pointer select-none'
                          key={i}
                          {...b}
                        >
                          {b.title}
                        </div>
                      ))}
                  {/* <button className="p-2 bg-gray-500 text-white">
              Edit Roster
              </button> */}
                </div>
                <div className='flex flex-wrap gap-2 w-fit'>
                  <SubstitudesMatch editMode={editMode} />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
