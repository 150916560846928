import { Navigate, useParams } from 'react-router';
import { addCurrentTimeToDate, dateToLocal } from '../../../../../../utils/dateFormatter';
import useSuccessToast from '../../../../../../hooks/use-success-toast';
import { OutsideClick } from '../../../../../../components/outside-click';
import { Formik } from 'formik';
import ModalContent from '../../../../../../components/modal-content';
import {
  useEditSeasonStageMutation,
  useViewSingleSeasonQuery,
  useViewSingleSeasonStageQuery,
} from '../../../../../../slices/seasonApiSlice';
import { useEffect, useState } from 'react';
import {
  SeasonProgressStatus,
  SeasonStageTeamSelectionMethod,
  StageTypes,
} from '../../../../../../utils/constants';
import { EditStageVSchema } from './schema/edit-stage-v-schema';

const EditSeasonStage = () => {
  const param = useParams();
  const [chosenTeam, setChosenTeam] = useState<object[]>([]);
  const [chosenTeamSelectionMethod, setChosenTeamSelectionMethod] = useState(0);
  const [editSeasonStage, { isLoading: editIsLoading, isSuccess: editIsSuccess }] =
    useEditSeasonStageMutation();
  const { data: seasonStageData, isLoading: seasonStageIsLoading } = useViewSingleSeasonStageQuery(
    param.stageId,
  );
  const { data: seasonData } = useViewSingleSeasonQuery(param.id);
  useEffect(() => {
    setChosenTeam(seasonStageData?.teams ? [...seasonStageData?.teams] : []);
  }, [seasonStageData]);
  const formInputs = [
    {
      mainTitle: 'Stage Information',
      name: 'name',
      label: 'Stage Name',
      type: 'name',
      placeholder: 'Enter Stage name',
      isTrue: true,
      hidden: false,
    },
    {
      mainTitle: 'Stage Information',
      name: 'stage_type_id',
      label: 'Type',
      type: 'number',
      min: 0,
      placeholder: 'Stage Type',
      hidden: false,
      isSelect: true,
      options: Object.values(StageTypes)
        .filter(
          (type) => !(seasonData?.SeasonType.leaderboard && type.id === StageTypes.SkillSync.id),
        )
        .map((type) => ({ id: type.id, name: type.displayText })),
    },
    {
      mainTitle: 'Stage Information',
      name: 'stage_team_selection_type_id',
      label: 'Team Selection Type',
      type: 'text',
      placeholder: 'Team Selection Type',
      isTrue: true,
      isSelect: true,
      options: Object.values(SeasonStageTeamSelectionMethod).map((type) => {
        if (
          (seasonData?.SeasonStage?.length &&
            seasonData?.SeasonStage?.[
              seasonData.SeasonStage.findIndex(
                (stage: { id: number }) => stage.id === seasonStageData?.id,
              ) - 1
            ]?.status === SeasonProgressStatus.Completed) ||
          type.displayText !== SeasonStageTeamSelectionMethod.SelectTopTeams.displayText
        )
          return {
            id: type.id,
            name: type.displayText,
          };
        else return { id: 0 };
      }),
    },
    {
      mainTitle: 'Stage Information',
      name: 'teams',
      label: 'Teams',
      type: 'text',
      placeholder: 'Enter teams',
      isTrue: true,
      search: 'seasonStageTeam',
      seasonTeams: seasonData?.teams,
      seasonStageTeams: seasonStageData?.teams,
      prevSeasonStageTeams:
        seasonData.SeasonStage.findIndex(
          (stage: { id: number }) => stage.id === seasonStageData?.id,
        ) > 0
          ? seasonData.SeasonStage[
              seasonData.SeasonStage.findIndex(
                (stage: { id: number }) => stage.id === seasonStageData?.id,
              ) - 1
            ].standing
          : ['none'],
      setChosenTeam,
      chosenTeamSelectionMethod,
      chosenTeam,
    },
    {
      mainTitle: 'Stage Information',
      name: 'start_time',
      label: 'Start date',
      type: 'datetime-local',
      placeholder: 'Start date',
      isTrue: true,
    },
    {
      mainTitle: 'Stage Information',
      name: 'end_time',
      label: 'End date',
      type: 'date',
      placeholder: 'End date',
      isTrue: true,
    },
  ];
  function handleSubmit(values: any) {
    const body = {
      ...values,
      start_time: values.start_time + ':00.000Z',
      end_time: addCurrentTimeToDate(values.end_time),
      teams: [],
    };
    delete body.stage_team_selection_type_id;
    chosenTeam.forEach((t: any) => body.teams.push({ id: t.id }));
    if (values.end_time && values.end_time !== '-') {
      body.end_time = addCurrentTimeToDate(values.end_time);
    } else delete body.end_time;

    editSeasonStage({
      stageId: param.stageId,
      body: body,
    });
  }

  useSuccessToast(editIsSuccess, 'Season Stage has been edited successfully.');
  if (editIsSuccess) return <Navigate to='../' />;
  if (seasonStageIsLoading) return <p>Loading...</p>;
  const newSeasonStageSchema = EditStageVSchema();
  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={newSeasonStageSchema}
        initialValues={{
          name: seasonStageData.name,
          stage_type_id: seasonStageData?.stage_type_id,
          start_time: (seasonStageData?.start_time).split(':').slice(0, 2).join(':'),
          end_time:
            dateToLocal(seasonStageData?.end_time)?.split('T')[0] !== '-'
              ? dateToLocal(seasonStageData?.end_time)?.split('T')[0]
              : '',
          stage_team_selection_type_id: SeasonStageTeamSelectionMethod.ManuallySelectTeams.id,
        }}
        onSubmit={handleSubmit}
      >
        {({ values, errors }) => {
          useEffect(() => {
            if (!values) return;
            setChosenTeamSelectionMethod(+values.stage_team_selection_type_id);
          }, [values]);
          return (
            <ModalContent
              formInputs={formInputs.filter((formInput) => !formInput.hidden)}
              isLoading={editIsLoading}
              name='Edit Season Stage'
            />
          );
        }}
      </Formik>
    </>
  );
};

export default EditSeasonStage;
