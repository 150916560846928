import { Formik } from 'formik';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../../components/outside-click';
import {
  useCreateSeasonStageMutation,
  useViewSingleSeasonQuery,
} from '../../../../slices/seasonApiSlice';
import { NewStageVSchema } from './schema/new-stage-v-schema';
import ModalContent from '../../../../components/modal-content';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addCurrentTimeToDate } from '../../../../utils/dateFormatter';
import {
  SeasonProgressStatus,
  SeasonStageTeamSelectionMethod,
  StageTypes,
} from '../../../../utils/constants';
import { Checkbox } from '../../../../components/ui/checkbox/Checkbox';

const NewSeasonStage = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [chosenTeam, setChosenTeam] = useState<object[]>([]);
  const [generateWeeks, setGenerateWeeks] = useState(false);
  const [chosenTeamSelectionMethod, setChosenTeamSelectionMethod] = useState<number>();
  const [createSeasonStage, { isLoading: createIsLoading, isSuccess: createIsSuccess }] =
    useCreateSeasonStageMutation();
  const { data: seasonData } = useViewSingleSeasonQuery(param.id);
  const formInputs = [
    {
      mainTitle: 'Stage Information',
      name: 'name',
      label: 'Stage Name',
      type: 'name',
      placeholder: 'Enter Stage name',
      isTrue: true,
      hidden: false,
    },
    {
      mainTitle: 'Stage Information',
      name: 'stage_type_id',
      label: 'Type',
      type: 'number',
      min: 0,
      placeholder: 'Stage Type',
      hidden: false,
      isSelect: true,
      options: Object.values(StageTypes)
        .filter(
          (type) => !(seasonData?.SeasonType.leaderboard && type.id === StageTypes.SkillSync.id),
        )
        .map((type) => ({ id: type.id, name: type.displayText })),
    },
    {
      mainTitle: 'Stage Information',
      name: 'stage_team_selection_type_id',
      label: 'Team Selection Type',
      type: 'text',
      placeholder: 'Team Selection Type',
      isTrue: true,
      isSelect: true,
      options: Object.values(SeasonStageTeamSelectionMethod).map((type) => {
        if (
          (seasonData?.SeasonStage?.length &&
            seasonData?.SeasonStage?.[seasonData?.SeasonStage?.length - 1].status ===
              SeasonProgressStatus.Completed) ||
          type.displayText !== SeasonStageTeamSelectionMethod.SelectTopTeams.displayText
        )
          return {
            id: type.id,
            name: type.displayText,
          };
        else return { id: 0 };
      }),
    },
    {
      mainTitle: 'Stage Information',
      name: 'teams',
      label: 'Teams',
      type: 'text',
      placeholder: 'Enter teams',
      isTrue: true,
      search: 'seasonStageTeam',
      seasonTeams: seasonData?.teams,
      prevSeasonStageTeams: seasonData.SeasonStage.length
        ? seasonData.SeasonStage[seasonData.SeasonStage.length - 1].standing
        : [],
      setChosenTeam,
      chosenTeamSelectionMethod,
      chosenTeam,
      // addTeamsToSeason: true
    },
    {
      mainTitle: 'Stage Information',
      name: 'num_groups',
      label: 'Number of Groups',
      type: 'number',
      placeholder: 'Number of Groups',
    },
    {
      mainTitle: 'Stage Information',
      name: 'start_time',
      label: 'Start Date',
      type: 'date',
      placeholder: 'Start date',
      isTrue: true,
    },
    {
      mainTitle: 'Stage Information',
      name: 'end_time',
      label: 'End Date',
      type: 'date',
      placeholder: 'End date',
      isTrue: true,
    },
  ];
  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  function handleSubmit(values: any) {
    const body = {
      ...values,
      start_time: addCurrentTimeToDate(values.start_time),
      teams: [],
    };
    chosenTeam.forEach((t: any) => body.teams.push({ id: t.id }));
    if (values.end_time) {
      body.end_time = addCurrentTimeToDate(values.end_time);
    } else delete body.end_time;
    body.season_id = seasonData.id;
    delete body.stage_team_selection_type_id;
    createSeasonStage({
      ...body,
    });
  }

  useEffect(() => {
    if (createIsSuccess)
      toast.success('Season stage has been cretead successfully.', { position: 'top-right' });
  }, [createIsSuccess]);

  if (createIsSuccess) {
    return <Navigate to='../' />;
  }

  const newSeasonStageSchema = NewStageVSchema();
  function handleCheckBox(): void {
    setGenerateWeeks((prev) => !prev);
  }
  return (
    <>
      <OutsideClick />
      <Formik
        enableReinitialize
        validationSchema={newSeasonStageSchema}
        initialValues={{ ...initialValues }}
        onSubmit={handleSubmit}
      >
        {({ values, errors }) => {
          useEffect(() => {
            if (!values) return;
            setChosenTeamSelectionMethod(+values.stage_team_selection_type_id);
          }, [values]);
          return (
            <>
              <div className='py-4' onClick={() => handleCheckBox()}>
                <div className='flex items-center gap-1.5 hover:bg-slate-50 cursor-pointer'>
                  <Checkbox
                    checked={generateWeeks}
                    // onClick={() => handleCheckBox()}
                  />
                  <label htmlFor={'invite'} className='flex-1 cursor-pointer'>
                    CO-OP Teams
                  </label>
                </div>

                <hr />
              </div>
              <ModalContent
                formInputs={formInputs.filter((formInput) => !formInput.hidden)}
                isLoading={createIsLoading}
                name='New Season Stage'
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default NewSeasonStage;
