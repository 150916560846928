import React from 'react';
export function TableHead({
  tableList,
  bool,
  oneMore,
}: {
  tableList: string[];
  bool?: boolean;
  oneMore?: boolean;
}) {
  return (
    <thead className='bg-gray-50 table-fixed'>
      <tr>
        {tableList.map((t) => (
          <th
            key={t}
            scope='col'
            className='px-6 py-3 text-xs text-center font-medium text-gray-500 uppercase tracking-wider'
          >
            {t.split('_').join(' ').trim()}
          </th>
        ))}
        {oneMore && (
          <th
            scope='col'
            className='px-6 py-3 text-xs text-center font-medium text-gray-500 uppercase tracking-wider'
          ></th>
        )}

        {bool && (
          <th scope='col' className='relative px-6 py-3'>
            <span className='sr-only'>Edit</span>
          </th>
        )}
      </tr>
    </thead>
  );
}
