import * as yup from 'yup';
import { SchemaMessages } from '../../utils/constants';
import { checkStartDate, checkStartDateAndSpecificDate } from '../../utils/utils';
const today = new Date();
today.setHours(0, 0, 0, 0);
export const addMatchToWeekSchema = (start_time?: string) =>
  yup.object().shape({
    match_date: yup
      .mixed()
      .transform((value, originalValue) => originalValue)
      // .test('test', 'Selected date must be from today and after the season week start date!', (value, ctx) =>
      //   checkStartDateAndSpecificDate(value, ctx, start_time ? new Date(start_time) : undefined)
      // )
      .required('Required!'),
    home_team: yup.number().required('Required'),
    away_team: yup
      .number()
      .notOneOf([yup.ref('home_team')], 'Away team should be different from home team!')
      .nullable(),
  });
